<template>
  <div class="main">
  <g-loading :loading="loading" />
  <div class="no-data common" v-if="list.length===0&&!loading">
    <img width="185" src="../../assets/img/order/no-date.png"/>
    暂无数据
  </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="list common" v-else>
      <van-list
        :offset="100"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
      <ul v-for="i in list" :key="i.id" class="parent" @click="jup_article(i)">
        <li class="parent-item">
          <div v-if="i.read" class="left">
            <span v-if="i.from_name">{{i.from_name}}（{{i.from_type === 1 ? '患者' : '医生'}}）</span>
            <span class="reply-text1" v-if="i.to_name">回复了</span>
            <span v-if="i.to_name">您</span>
          </div>
          <van-badge dot class="left" v-else>

            <span v-if="i.from_name">{{i.from_name}}（{{i.from_type === 1 ? '患者' : '医生'}}）</span>
            <span class="reply-text1" v-if="i.to_name">回复了</span>
            <span v-if="i.to_name">您</span>

          </van-badge>
          <div class="time">{{format_date(i.create_time,'yyyy-MM-dd HH:mm:ss')}}</div>
        </li>
      </ul>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import {get_no_read_comments} from './service'
import {format_date} from '../../utils/format'
import { mapMutations } from "vuex";
export default {
  data () {
    return {
      list: [],
      finished:false,
      loading: false,
      timer: null,
      page_size: 20,
      page: 0,
      total: 0,
      error: false,
      has_data: false,
      refreshing:false
    }
  },
  props: {
  },
  computed:{
    noMore () {
      return this.has_data&&(this.total === 0 || (this.list.length >= this.total))
    },
  },
  watch: {
  },
  created() {
    this.onLoad()
    this.clearState()
  },
  methods: {
    ...mapMutations("no_read", [
      'update_no_read',
      'clearState'
    ]),
    format_date,
    onLoad() {
      if(this.timer) clearTimeout(this.timer)
      if(this.noMore || this.error) {
        this.loading = false
        this.finished = true
        return
      }
      this.loading = true
      this.timer = setTimeout(()=>{
        this.page = this.page + 1
        this.search_no_read_comments()
      },1000)
    },
    onRefresh() {
      this.finished = false
      this.loading = false
      this.has_data = false
      this.error = false
      this.list = []
      this.page = 0
      this.refreshing = false
      this.onLoad()
    },
    async search_no_read_comments () {
      let {page,page_size} = this
      let params = {page:page-1,page_size}
      try {
        this.loading = true
        const {data} = await get_no_read_comments(params)
        this.total = data.total_elements || 0
        this.list = this.list.concat(data.data)
        this.has_data = true
      }catch(e){
        console.log(e)
        this.error = true
      } finally {
        this.loading =  false
      }
    },
    async jup_article(item){
      if(!item.article_url) return
      this.update_no_read(true)
      this.$router.replace({
        path: item.article_url,
      });
    }
  }
}
</script>
<style scoped>


.main{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  & .common{
    flex:1;
    overflow: auto;
  }
}

.parent-item {
  display: flex;
  align-items: center;
  & .time {
    text-align: right;
    color: #909399;
    padding-left:10px;
  }
  & .left {
    flex:1;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
    padding-bottom: 3px;
    min-width: 120px;
  }
}
.parent {
  padding: 12px 0;
  border-bottom:1px solid #E0E0E0;
  margin: 0 20px;
}
.common{
  padding:20px 0;
}
>>>.van-list__finished-text {
  font-size: 12px;
}
>>>.van-badge--fixed {
  position: absolute;
  top: 0;
  right: initial;
  left: -10px;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color:rgba(112, 112, 112, 0.5);
  }
}
</style>

