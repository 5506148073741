import api from '../../utils/api'
// 评论详情
export async function get_no_read_comments (payload) {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data:{
  //         total_num1: 100,
  //         total_num2: 200,
  //         title: '文章名称',
  //         total_elements: 2,
  //         data: [
  //           {
  //             id: 1,
  //             article_id: 1,
  //             content: '内容一德国队获得更好的多好的更好的更好的更好的电话都过得更好的更好的过 更好的更好的更好的过的好感动好感动好的过好感动好感动好的更好的过dodged',
  //             from_uid: '11',
  //             to_uid: '22',
  //             parent_id: 1,
  //             from_name: '张三',
  //             to_name: '李四',
  //             update_time: new Date('2022-10-11'),
  //             type: 1,
  //             read: false
  //           },
  //           {
  //             id: 2,
  //             article_id: 2,
  //             content: '内容一德国队获得更好的多好的更好的更好的更好的电话都过得更好的更好的过 更好的更好的更好的过的好感动好感动好的过好感动好感动好的更好的过dodged',
  //             from_uid: '11',
  //             to_uid: '22',
  //             parent_id: 2,
  //             from_name: '张三',
  //             update_time: new Date('2022-10-11'),
  //             type: 2,
  //             to_name: '李四',
  //             read: true,

  //           },
  //           {
  //             id: 3,
  //             article_id: 3,
  //             content: '内容一德国队获得更好的多好的更好的更好的更好的电话都过得更好的更好的过 更好的更好的更好的过的好感动好感动好的过好感动好感动好的更好的过dodged',
  //             from_uid: '11',
  //             to_uid: '22',
  //             parent_id: 3,
  //             from_name: '张三',
  //             to_name: '李四',
  //             update_time: new Date('2022-10-11'),
  //             type: 2,
  //             read: false
  //           }
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/operation/comments/my_reply`,{params: payload})
}


